.consultation-container {
  display: flex;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}

.consultation-inner {
  max-width: 1100px;
  background-color: #e7e7e6;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0px 2px 8px grey;
}

.consultation-main {
  padding-top: 30px;
}

.hr-line {
  background-color: grey;
  width: 100%;
  height: 1px;
}

@media screen and (max-width: 768px) {
  .consultation-inner {
    width: 90%;
    background-color: #dfdfdb;
    border-radius: 0px;
    padding: 0px;
    box-shadow: none;
  }
}
