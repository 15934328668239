.footer-container {
  display: flex;
  background-color: var(--bg-color-nav);
  padding: 10px;
  justify-content: space-around;
  width: 100vw;
  /* margin-top: 20px; */
}

.footer-links {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.footer-link {
  color: var(--text-color-nav);
  cursor: pointer;
}

.footer-link:hover {
  color: white;
}

.footer-logo {
  color: var(--text-color-nav);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    padding: 20px;
  }

  .footer-links {
    flex-direction: column;
  }

  .footer-logo {
    margin-bottom: 26px;
    /* border-bottom: 1px solid grey; */
    font-size: 1.4rem;
  }

  .footer-link {
    /* border-bottom: 1px solid white; */
    width: 100%;
    text-align: center;
  }
}
