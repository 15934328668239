:root {
  --text-color: #fff;
}

.navbar {
  background-color: var(--bg-color-nav);
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
  align-items: flex-start;
  /* margin-bottom: 30px; */
  /* box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.7); */
  /* padding: 20px; */

  /* position: relative; */
  /* height: 600px; */
  padding-top: 570px;
  padding-bottom: 30px;
  padding-left: 130px;
  padding-right: 20%;
  top: 0;
  z-index: 111;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.47) 40%
    ),
    url("../assets/photo4.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.navbar-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  /* background-color: green; */
}

.navbar-link {
  color: var(--text-color);
  font-weight: 700;
  font-size: 1.5rem;
  cursor: pointer;

  padding: 12px 0;
  padding-bottom: 6px;
  height: 100%;
  border-bottom: 2px solid transparent;
  transition: all 0.4s;
  text-shadow: 0px 0px 2px black;
}

.navbar-logo {
  font-size: 2.7rem;
  color: var(--text-color);
  text-shadow: 0px 0px 3px black;

  /* font-style: italic; */
  font-weight: 700;
  transform: translateY(-6px);
  /* padding-top: 8px; */
  /* font-family: Arial, Helvetica, sans-serif; */
}

.menu-button {
  display: none;
  position: absolute;
  top: 0.2rem;
  right: 0.3rem;
  color: #fff;
}

.navbar-link.active {
  /* background-color: var(--bg-color-hover); */
  border-bottom: 2px solid white;
  color: white;
}

.link {
  text-decoration: none;
}

.navbar-link:hover {
  border-bottom: 2px solid var(--text-color);
}

.page-title {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 600px;
    padding-top: 0;
    padding-bottom: 0;
    background-attachment: scroll;
  }
  .menu-button {
    display: block;
  }
  .navbar-logo {
    position: absolute;
    top: 6px;
    font-size: 1.8rem;
    transform: translateY(0px);
  }

  .navbar-links {
    max-height: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-top: 1px solid --bg-color-hover;
    height: 140px;
    width: 100%;
    gap: 0;
    margin-top: 70px;
    transition: max-height 0.4s;
  }

  .navbar-link {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    text-shadow: none;
    font-weight: 400;
  }

  .navbar-links.active {
    max-height: 400px;
  }

  .navbar-link.active {
    /* background-color: var(--bg-color-hover); */
    border-bottom: none;
    color: white;
  }
}
