.about-me-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 90px;
}

.about-me-container-inner {
  max-width: 1100px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #e7e7e6;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0px 2px 7px grey;
}

.about-me-main {
  max-width: 1100px;
  display: flex;
  /* background-color: yellow; */
  /* flex-direction: column; */
  padding: 0px 0;
  gap: 10px;
}

.about-me-info,
.about-me-image {
  flex: 1;
  /* padding: 20px; */
  padding-top: 20px;
}

.page-title {
  font-size: 2rem;
  font-weight: 700;
}

.about-me-info ul li {
  font-size: 1.2rem;
}

.about-me-image {
  max-width: 500px;
}

img {
  border-radius: 3px;
}

.hr-line {
  background-color: grey;
  width: 100%;
  height: 1px;
}

.page-title {
  margin-top: 0px;
}

@media screen and (max-width: 768px) {
  .about-me-main {
    flex-direction: column;
  }

  .second-main {
    flex-direction: column-reverse;
  }

  .about-me-container-inner {
    width: 90%;
    background-color: #dfdfdb;
    border-radius: 0px;
    padding: 0px;
    box-shadow: none;
  }

  .about-me-info {
    padding: 10px 0;
  }
}
