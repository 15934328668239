.contact-main-bottom {
  display: flex;
  padding: 30px 0;
  /* flex-wrap: wrap; */

  gap: 40px;
  width: 100%;
}

.contact-main-left,
.contact-main-right {
  flex: 1;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.contact-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}

.contact-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.contact-item-title {
  font-size: 1.2rem;
}

.contact-main-right {
  display: flex;
  flex-direction: column;
}

.contact-icon {
  scale: 1.2;
}

.contact-icon-small {
  scale: 1.5;
}

.message-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.hr-line {
  background-color: grey;
  width: 100%;
  height: 1px;
}

.hr-line-responsive {
  display: none;
}

.input-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.map {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.page-title {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .form-input {
    width: 100%;
  }

  .contact-main-bottom {
    flex-direction: column;
  }

  .contact-main {
    width: 90%;
    background-color: #f4fdff;
    border-radius: 0px;
    padding: 0px;
    box-shadow: none;
  }

  .contact-container {
    width: 100vw;
    margin: 0;
    padding: 10px 0;
    padding-top: 60px;
  }

  .contact-main-bottom {
    /* padding: 10px; */
    align-items: center;
  }

  .contact-main-right,
  .contact-main-left {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .message-form {
    width: 100%;
    align-items: center;
  }

  .input-field {
    width: 100%;
  }

  .map {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hr-line-responsive {
    display: block;
  }
}
