:root {
  --bg-color-hover: #aea297;
  --text-color-nav: #dfdfdb;
  --bg-color-nav: rgb(71, 71, 71);
  --bg-color: #f4fdff;
  --bg-color-hover: #aea297;
  --text-color: #222;
}
/*  */
* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;

  overflow-x: hidden;
}

.img-container {
  max-width: 400px;
  flex: 1;
}

img {
  width: 100%;
}

.text {
  flex: 1;
}

.app-container {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100vh;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 80px;
  overflow-y: auto;
  perspective: 10px;
}

.container {
  width: 1100px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #f4fdff;
  border-radius: 3px;
  padding: 30px;
  box-shadow: 0px 2px 5px grey;
  margin: 0 20px;
  margin-top: 100px;
}

.mtop {
  margin-top: 100px;
}

p {
  font-size: 1.35rem;
}

/* @media screen and (min-width: 1150px) {
  .container {
    width: 1100px;
  }
} */

@media screen and (max-width: 1150px) {
  .container {
    width: 95vw;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100vw;
    background-color: var(--bg-color);
    border-radius: 0px;
    /* padding: 0px; */
    box-shadow: none;
    margin: 0;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  p {
    font-size: 1.1rem;
  }

  .app-container {
    gap: 20px;
  }
}
